.chart-wrapper {
  width: calc(100% - 20px) !important;
}
.container-fluid-custom {
  padding-bottom: 100px !important;
}
.button-default {
  margin-left: 10px;
  color: white;
  background: #39b2d5;
}
.button-default:hover{
 border: 1px solid #39b2d5; 
 color:  #39b2d5;
 background: transparent;
}
.breadcrumb {
  border-bottom: none !important;
}
.button_login {
  width: 100% !important;
  margin-top: 3%;
  border-radius: 30px;
  height: 100%;
  color: white;
  background: #51acfd !important;
  font-size: 1em;
}
.button_login:hover {
  height: 100%;
  color: #51acfd;
  background: #fff !important;
  border: 4px solid #51acfd;
  border-radius: 30px;
}
.cube {
    top: 10vh;
    left: 90vw;
    width: 10px;
    height: 10px;
    border: solid 0.5px #769be6;
    transform-origin: top left;
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    animation: cube 12s ease-in forwards infinite;
    z-index: 8;
    position: absolute;
}
.cube:nth-child(2) {
    animation-delay: 2s;
    left: 10vw;
    top: 50vh;
    width: 5px;
    height: 5px;
    position: absolute;
}
.cube:nth-child(3) {
    animation-delay: 3s;
    left: 60vw;
    top: 80vh;
    width: 8px;
    height: 8px;
    position: absolute;
}
@keyframes cube {
  from {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
  }
  to {
    transform: scale(20) rotate(960deg) translate(-50%, -50%);
    opacity: 0;
  }
}
.word_side {
  position: absolute;
  color: white;
  font-size: 2em;
  margin-top: -12%;
  margin-left: 18%;
}
.hafiz_image {
  opacity: 0.5;
  width: 50% !important;
}
.title_welcome {
  position: absolute;
  color: white;
  font-size: 3em;
  font-weight: 600;
  margin-top: -15%;
  margin-left: 12%;
}
.app-custom {
   background: linear-gradient(306deg, rgba(179,141,255,1) 0%, rgba(22,191,252,1) 100%) !important;
}
.title_homepage {
  color: #52acfd;
  text-align: center;
  margin-bottom: 6%;
}
.button-unknownlog {
  background: white;
  border: 2px solid lightblue;
  border-radius: 60px;
}
.button-unknownlog:hover {
  background: lightblue;
  border: 2px solid white;
  border-radius: 60px;
}
.button-logout {
  background: #39b2d5;
  border: 2px solid lightblue;
  border-radius: 60px;
}
.button-logout > a {
  color: white !important;
}
.sidebar {
  display: flex;
  flex-direction: column;
  padding: 0;
  color: #2f353a  !important;
  background: #fff !important;
}
.sidebar .nav-title {
  color : #73818f !important;
}
.sidebar .nav-link {
  color: #73818f !important;
}
.sidebar .nav-link:hover {
  color: #fff !important;
  background: #73818f !important;
}
.sidebar .nav-link.active {
  color: #fff !important;
}
.tableBootstrap{
  margin-right: auto !important;
  margin-left: auto !important;
  margin-bottom: 35px;
}
.navpillsCustom .show > .nav-link{
  background-color: #efefef;
  color: #333;
  }
.formDate{
  border-radius: 5px;
  color: #73818f;
  border:  1px solid #efefef;
  padding: 5px;
}
.customHeight{
  min-height: 500px;
}
.content_type, .entity,.intent,.source{
  width:100%;
  height:35px;
  min-height:35px;
}
.colorText{
  color: #5c6873;
}
.totalPagination{
  color:#20a8d8;
}
.intentBehavioursStyle{
  cursor: pointer;
}
.labelNumberForm{
  padding-top: 20px;
}
.labelForm{
  padding-top: 10px;
  padding-bottom: 10px;
}
.formborder{
  border-bottom: 1px solid #efefef;
  padding-bottom: 20px;
}
.cursor{
  cursor: pointer;
}
.cardTableDash{
  max-height: 700px;
  min-height: 600px;
  /* height: 600px; */
}
.tes{
  max-height: 150px;
}
.cardPiePengguna{
  border: none;
  max-height: 160px;
  height: 150px;
}
.cardTotalPengguna{
  border: none;
  color: #ffff;
  max-height: 160px;
  height: 150px;
  background-color:#01b0f0;
  box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.07);
}
.cardTotalPenggunaContent{
  border: none;
  color: #ffff;
  max-height: 160px;
  height: 150px;
  background-color:#c989ec;
  box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.07);
}
.cardPenggunaBaru{
  border: none;
  color: #ffff;
  max-height: 160px;
  height: 150px;
  background-color:#017bff;
  box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.07);
}
.cardPenggunaBaruContent{
  border: none;
  color: #ffff;
  max-height: 160px;
  height: 150px;
  background-color:#5e77ff;
  box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.07);
}
.customclass{
  border:1px solid #000;
 padding: 3px;
}
.divbread .brd{
  border: none !important;
}

.breadcrumbsstyle{
  background-color: blue !important;
  border-bottom: none !important;
}
.center{
	text-align: center;
}
.borderNone{
	border: none !important;
}
.cardcustom {
	border: none;
	box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.07);
	padding: 20px;
}
.cardcustompadding {
  border: none;
  box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.07);
  /* padding: 20px; */
}
.border-none {
	border: none;
}
.hr {
	border: 5px solid green;
  	border-radius: 5px;
  	padding: 0px;
}

.btn-outline-secondary:not(.disabled).active {
	background-color: #e2e2e2 !important;
}
.widthTable{
	width: 50% !important;
}
.table-bordered td{
	border: none !important;
}
.react-bs-table .table-bordered>thead>tr:first-child>th{
	color: #6b6f71;
}
.react-bs-table-bordered{
	border : none !important;
	border-top: 1px solid #ddd !important;
	border-radius: 0 !important;
}
.cardstyleUfogreen{
  box-shadow:  0px 0px 15px 5px rgba(0,0,0,0.07);
  border : none ;
  background-color: #2ed573;
}
.cardstyleDeepBlue{
  /* box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.07); */
  box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.07);
  border : none ;
  background-color: #113F67;
}
.cardstyleDeepBlue:hover{
  background-color: #124068d4;
}
.cardstyleContent{
  box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.07);
  border : none ;
  background-color: #5e77ff;
  /* background: linear-gradient(0deg, rgba(255,182,185,1) 0%, rgba(143,241,218,1) 42%); */
}
.cardstyleContent1{
  box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.07);
  border : none ;
  background-color: #c989ec;
}
.cardstyleContent2{
  box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.07);
  border : none ;
  background-color: #8495f7;
}
.cardstyleContent3{
  box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.07);
  border : none ;
  background-color: #a7b4ff;
}
.cardstyleOceanBlue{
  box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.07);
  border : none ;
  background-color: #34699A;
}
.cardstyleDarkBlue{
  box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.07);
  border : none ;
  background-color: #408AB4;
}
.cardstyleBlue{
  box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.07);
  border : none ;
  background-color: #65C6C4;
}
.cardstyleCoral{
  box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.07);
  border : none ;
  background-color : #ff7f50;
}
.cardstyleApple{
  box-shadow : 0px 0px 15px 5px rgba(0,0,0,0.07);
  border : none ;
  background-color : #ea8685;
}
.cardstyleLightBlue{
  background-color: #00FFFF;
}
.cardstyle{
  box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.07);
  border : none ;
  background-color: #2ed573;
}
.cardstylePink{
  box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.07);
  border : none ;
  background-color: #ffb6b9;
}
.cardstyleLightRose{
  box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.07);
  border : none ;
  background-color: #ffa1a3;
}
.cardstyleBlueRose{
  box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.07);
  border : none ;
  background-color: #8ff1da;
}
.cardstyleBlueSea{
  box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.07);
  border : none ;
  background-color: #61c0bf;
}
.behavioursCard{
  border-top: 1px solid #efefef;
  padding-top:10px;
  margin-top: 10px;
}
.mh{
  max-height: 150px;
}
.styleCardLabel{
  margin-top: 10px;
  font-size: 20px;
  text-overflow: ellipsis;
  min-width: 100%;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-align: center !important;
}
.totalUserConnectedChart{
  border-bottom: 30px;
  border: none;
  border-radius: 30px;
}
@media (min-width: 1281px) { 
  
  .word_side {
    position: absolute;
    color: white;
    font-size: 2em;
    margin-top: -12%;
    margin-left: 15% !important;
  }
  .title_welcome {
    position: absolute;
    color: #fff;
    font-size: 3em;
    font-weight: 600;
    margin-top: -15%;
    margin-left: 7% !important;
  } 
  .hafiz_image {
    opacity: 0.5;
    width: 50% !important;
    width: -moz-max-content !important;
  }
}
@media (min-width: 1368px) and (max-width:1919px){
 .word_side {
  position: absolute;
  color: white;
  font-size: 2em;
  margin-top: -12%;
  margin-left: 19% !important;
}
.title_welcome {
  position: absolute;
  color: blue;
  font-size: 3em;
  font-weight: 600;
  margin-top: -15%;
  margin-left: 2% !important;
} 
}